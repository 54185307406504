/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

// Color Define

$color1: #5367fc;
$color2: #008490;
$color3: #05cd99;
$color4: rgba(241, 113, 113, 0.1);
$color5: rgba(5, 205, 153, 0.18);
$color6: #0a3294;
$color7: #ffffff;
$color8: rgba(255, 255, 255, 0.15);
$color9: rgba(83, 103, 252, 0.1);
$color10: rgba(241, 91, 77, 0.12);
$color11: #c2c2c8;
$color12: #ffc84b;
$color13: #f17171;
$color14: #e9ebec;
$color15: #f9fafd;
$color16: #f5f5f5;
$color17: rgba(76, 78, 100, 0.12);
$color18: #f3f6f9;
$color19: rgba(5, 205, 153, 0.1);
$color20: rgba(83, 103, 252, 0.3);
$color21: #E9E9EF;
$color22: rgba(61, 120, 227, 0.08);
$color23:  #F0F4FF;
$colorText1: #545a6d;
$colorText2: #a3aed0;
$colorText3: #f15b4d;
$colorText4: #878a99;
$colorText5: #495057;
$colorText6: #919da9;
$colorText7: #9599ad;
$colorText8: #0AB39C;
$colorText9: #ebac17;

// Color Define End

.btn-read {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  transform: translate(10%, -5%);
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  cursor: pointer;
  background-color: inherit;
  border: none;
  outline: none;
  color: $color1;
}
.btn-read:hover {
  color: $color7;
}

.btn-read:hover .background-read {
  height: inherit;
  width: 100%;
  top: 55%;
}

.btn-read .background-read {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1px;
  width: 100%;
  background-color: $color1;
  z-index: -1;
  border-radius: 5px;
  transition: all 0.3s;
}

.active-feature-button {
  //height: 39px;
  background-color: rgba(255, 223, 53, 1);
  color: black;
  border-radius: 5px;
  border: none
}
.active-feature-button:hover {
  background-color: rgb(226, 196, 48);
  color: black;
}

// Old Code
.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

// Old Code End

// custom css

// text Color

.text-color1 {
  color: $colorText1;
}
.text-color2 {
  color: $colorText2;
}

.text-color3 {
  color: $color7;
}

.text-color4 {
  color: $colorText4;
}
.text-color5 {
  color: $colorText5;
}

.text-color6 {
  color: $color3;
}

.text-color66 {
  color: #069972;
}

.text-color7 {
  color: $color1 !important;
}

.text-color8 {
  color: $colorText6;
}

.text-color9 {
  color: $colorText7;
}

.text-color10 {
  color: $color12;
}

.text-color11 {
  color: $colorText3;
}

.text-color12 {
  color: $color6;
}

.text-color13 {
  color: $color11;
}

.text-color23 {
  color: $colorText8;
}

.text-color14 {
  color: $colorText9;
}


// text Color End

// Background Color
.background-color1 {
  background-color: $color6;
}

.background-color2 {
  background-color: $color8;
}

.background-color3 {
  background-color: $color3 !important;
}

.background-color4 {
  background-color: $color1;
}

.background-color5 {
  background-color: $color2;
}
.background-color6 {
  background-color: $color9 !important;
}
.background-color7 {
  background-color: $color12;
}
.background-color8 {
  background-color: $color13;
}
.background-color9 {
  background-color: $color16;
}
.background-color10 {
  background-color: $color18 !important;
}

.background-color11 {
  background-color: $color18;
}

.background-color12 {
  background-color: #018f16 !important;
}
.background-color13 {
  background-color: #cbb406 !important;
}
// Background Color End

//margins and padding

.margin-top1 {
  margin-top: 20px;
}

.margin-0 {
  margin: 0;
}
.padding-y-axis {
  padding-top: 20px;
  padding-bottom: 20px;
}
.margin-top-demounting15 {
  margin-top: -15px !important;
}
.margin-top-demounting20 {
  margin-top: -20px !important;
}
//margins and padding End
//width define
.width1 {
  width: 60%;
}
.width2 {
  width: 46%;
}

.width3 {
  width: 44%;
}

.width4 {
  width: 15%;
}

.width5 {
  width: 30%;
}

//width define End
.react-multiple-carousel__arrow {
  z-index: 1 !important;
}
.t-and-c {
  //background: $color6;
  margin: 0 2px 10px;
  border-radius: 5px;
}
.t-and-c p {
  margin: 0;
  font-size: 9px;
  color: $color11;
  padding: 1px;
  line-height: 12px;
}

// .react-multi-carousel-dot-list {
//   bottom: 10px !important;
// }

// Dashboard highlight component

.highlights-header {
  color: $color7;
  padding: 10px 0;
}

.highlights-slider-header {
  color: $color7;
  text-align: center;
}

.resend-button {
  border: none;
  background: transparent;
  color: $color1;
}

.resend-button1 {
  border: none;
  background: transparent;
  color: $color11;
}

.otp-box{
  border: 1px solid #e2dbdb !important;
  border-radius: 5px !important;
}

.otp-box-input{
width: 55% !important;
margin: 0 auto;
}

.highlights-slider-button {
  color: $color7;
  text-align: center;
  border: 0;
  background: border-box;
  text-decoration: underline;
  text-transform: uppercase;
}
.highlights-slider-button:hover {
  color: $color1;
}
.highlights-slider-button3 {
  color: $color7;
  text-align: center;
  border: 0;
  background: border-box;
  text-decoration: underline;
  text-transform: uppercase;
}
.highlights-slider-button3:hover {
  color: $color15;
  text-decoration: none;
}
.highlights-slider-button2 {
  color: $color7;
  text-align: center;
  border: 0;
  background: border-box;
  text-decoration: underline;
}
.highlights-slider-button2:hover {
  color: $color6;
}
.suggest-algo-hover:hover {
  background-color: $color6;
  border-radius: 50%;
  color: $color1;
}
.suggest-algo-algo-hover:hover {
  background-color: $color1;
  border-radius: 50%;
  color: $color6;
}
.title-button {
  background-color: transparent;
  border: none;
  padding: 0;
}
// Dashboard highlight component End

// Dashboard Active features component
.active-feature-button-normal {
  color: $color1;
  text-align: center;
  border: 0;
  // background-color: $color9;
  // border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 145px;
  height: 39px;
}

.active-feature-button-normal-text {
  margin: 0 auto;
}

.expire-text{
  color: $colorText3;
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
// Dashboard Active features component End
// Dashboard ADons component
.addons-button {
  width: 160px;
  height: 40px;
}

.addons-feature-button-normal {
  color: $color1;
  text-align: center;
  border: 0;
  background-color: $color9;
  border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 110px;
  height: 39px;
}
.addons-feature-button-normal-dis {
  color: #FFF;
  text-align: center;
  border: 0;
  background-color: $color1;
  opacity: .6;
  border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 110px;
  height: 39px;
}
.addons-feature-button-normal:hover {
  color: $color7;
  border: 0;
  background-color: $color1;
}

.addons-feature-button-normal11 {
  color: $color7;
  text-align: center;
  border: 0;
  background-color: $color1;
  border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 110px;
  height: 39px;
}
.addons-feature-button-normal11:hover {
  color: $color7;
  border: 0;
  background-color: #3a4cd2;
}

.addFund-feature-button-normal {
  color: $color1;
  text-align: center;
  border: 0;
  background-color: $color9;
  border-radius: 5px;
  padding: 5px 8px;
}
.order-table {
  overflow: auto !important;
  width: 70% !important;
  margin: 20px auto !important;
  border: 1px solid #5367fc !important;
  border-radius: 5px !important;
}
.addFund-feature-button-normal:hover {
  color: $color7;
  border: 0;
  background-color: $color1;
}

.compleate-order-btn {
    border: none;
    border-radius: 5px;
}
.rc-pagination{
  display: inline-flex;
  align-items: end !important;
}
.rc-pagination-prev button:after {
  content: '' !important;
  display: none !important;
}

.rc-pagination-next button:after {
  content: '' !important;
  display: none !important;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid $color1 !important;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  &:hover {
    background-color: $color1 !important;
    border: none !important;
    border-radius: 5px !important;
    color: #FFF !important;
  }
  &:active {
    color: #FFF !important;
  }
}

.rc-pagination-item-active {
  background-color: $color1 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #FFF !important;
}

.shopping-order-btn {
    background-color: rgba(83, 103, 252, 0.12);
    border: none;
    border-radius: 5px;
    color: $color1;
}

.shopping-order-i {
  background-color: rgba(83, 103, 252, 0.24) !important;
  color: $color1;
}

.shopping-order-i:hover {
  background-color: rgba(83, 103, 252, 0.28) !important;
  color: $color1;
}

.shopping-order-btn:hover {
    background-color: rgba(72, 90, 225, 0.18);
    border: none;
    border-radius: 5px;
    color: $color1;
}

.active-feature-button-normal {
  color: $color1;
  text-align: center;
  border: 0;
  background-color: $color9;
  border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 110px;
  height: 39px;
}

// .addons-feature-button-normal-text {
//   color: $color7;
//   text-align: center;
//   border: 0;
//   background-color: $color1;
//   border-radius: 5px;
//   // background: border-box;
//   // text-decoration: underline;
//   width: 110px;
//   height: 39px;
// }

.border-radius-1 {
  border-radius: 24px !important;
}

.border-radius-2 {
  border-radius: 5px !important;
}

.active-feature-button-exp {
  color: $colorText3;
  text-align: center;
  border: 0;
  background-color: $color10;
  border-radius: 5px;
  // background: border-box;
  // text-decoration: underline;
  width: 145px;
  height: 39px;
}
.active-feature-button-exp-text {
  margin: auto;
}
.active-feature-renew {
  color: $color1;
  text-align: center;
  border: 0;
  background: border-box;
  text-decoration: underline;
  // margin-top: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.active-feature-renew:hover {
  color: $color12;
}

.in-active-price-tab {
  background-color: $color22 !important;
  color: $color1 !important;
}

.price-off-badge {
  border-radius: 5px !important;
  background-color: rgba(5, 205, 153, 0.18) !important;
  color: $color3 !important;
  margin-left: 8px;
}

.price-off-badge2 {
  border-radius: 5px !important;
  background-color: rgba(5, 205, 148, 0.848) !important;
  color: $color7 !important;
  margin-left: 8px;
}

.price-off-badge-exp {
  border-radius: 5px !important;
  background-color: rgba(241, 91, 77, 0.18) !important;
  color: #F15B4D !important;
  margin-left: 8px;
}

// .active-feature-button-exp:hover {
//   color: $color7;
//   text-align: center;
//   border: 0;
//   background-color: $colorText3;
//   border-radius: 5px;
//   // background: border-box;
//   // text-decoration: underline;
//   width: 190px;
//   height: 39px;
// }

// Dashboard ADons component End
// Dashboard Margin overview component

.margin-overview-button1 {
  background-color: $color1;
}

.margin-overview-button2 {
  background-color: $color3;
}
// Dashboard Margin overview component End
.ref-and-earn-button {
  height: 28px;
  background-color: $color6;
  color: $color7;
  border: 0;
  border-radius: 5px;
}
.change-plan-btn {
  border: 1px solid $color1;
  border-radius: 5px;
  color: $color1;
}


.ref-and-earn-button:hover {
  background-color: #1a4dce;
  color: $color7;
}

.ref-and-earn-button2 {
  height: 30px;
  width: 150px;
  margin-left: -20px;
  background-color: $color1;
  color: $color7;
  border: 0;
  padding-left: 40px;
  border-radius: 25px !important;
}

.ref-and-earn-button2:hover {
  background-color: $color1;
  color: $color7;
}

.badge-color-profit {
  color: $color3;
  background-color: $color5;
  width: 75px;
  height: 18px;
}

.month-picker{
  width: 200px;
  padding: 5px 60px;
  border-radius: 5px;
  border: 1px solid #80808080;
}

.badge-color-loss {
  color: $colorText3;
  background-color: $color4;
  width: 70px;
  height: 18px;
}

.main-header-page {
  font-size: 24px;
  font-weight: 500;
}

.main-header-secondary-page {
  font-size: 14px;
  font-weight: 400;
}

.card-height-100 {
  height: calc(90% - #{$grid-gutter-width});
}

.card-height-110 {
  height: calc(100% - #{$grid-gutter-width});
}

.card-height-94 {
  height: calc(94% - #{$grid-gutter-width});
}

.card-height-90 {
  height: 90%;
}

.card-height-101 {
  height: 100%;
}

// Text font size and weight

.text-1 {
  font-size: 13px;
  font-weight: 400;
}

.text-2 {
  font-size: 21px !important;
  font-weight: 500 !important;
}

.text-3 {
  font-size: 10.5px;
  font-weight: 600;
}

.text-4 {
  font-size: 14px;
  font-weight: 500;
}
.text-4-1 {
  font-size: 11px;
  font-weight: 500;
}

.text-5 {
  font-size: 12px;
  font-weight: 500;
}

.text-6 {
  font-size: 20px;
  font-weight: 500;
}

.text-7 {
  font-size: 28px;
  font-weight: 600;
}

.text-8 {
  font-size: 21px;
  font-weight: 600;
}

.text-9 {
  font-size: 17.5px;
  font-weight: 500;
}

.text-99 {
  font-size: 17.5px;
  font-weight: 400;
}

.text-10 {
  font-size: 16px;
  font-weight: 500;
}

.text-101 {
  font-size: 14.5px;
  font-weight: 500;
}

.text-11 {
  font-size: 12px;
  font-weight: 400;
}

.text-12 {
  font-size: 32px;
  font-weight: 500;
}

.text-13 {
  font-size: 12px;
  font-weight: 500;
}
.text-14 {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}
.text-15 {
  font-size: 14px;
  font-weight: 400;
}
.text-16 {
  font-size: 12px;
  font-weight: 600;
}
// .text-16 {
//   font-size: 12px;
//   font-weight: 300;
// }

.text-17 {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.text-18 {
  font-size: 13px;
  font-weight: 600;
}
.text-19 {
  font-size: 15px;
  font-weight: 500;
}

.text-20 {
  font-size: 14px;
  font-weight: 600;
}

.text-21 {
  font-size: 12px;
  font-weight: 300;
}

.text-22 {
  font-size: 16px;
  font-weight: 600;
}

.text-23 {
  font-size: 40px;
  font-weight: 500;
}

.text-24 {
  font-size: 13px;
  font-weight: 300;
}

.text-25 {
  font-size: 60px;
  font-weight: 500;
}

.text-26 {
  font-size: 16px;
  font-weight: 300;
}

.text-27 {
  font-size: 20px;
  font-weight: 400;
}

.text-28 {
  font-size: 18px;
  font-weight: 600;
}
.text-29 {
  font-size: 10px !important;
  font-weight: 600 !important;
}

.text-30 {
  font-size: 26px;
  font-weight: 500;
}
// Text font size and weight End


@media (max-width: 767px) {
  .mobile-view-cashbal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .mob-txt-amt{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      span{
        &:first-child{
          font-size: 14px;
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}




.portfolio-icon-div {
  background-color: #F7F7FF;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
}
.portfolio-icon-div-text {

  color: #F7F7FF;
  background-color: $color1;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.avatar-xs-port {
  height: 2.1rem;
  width: 2.1rem;
}

.card-header:first-child {
  border-bottom: 0;
}

.card-header-highlight {
  background-color: $color2;
}

.card-invest {
  margin-bottom: 0;
  box-shadow: $card-box-shadow;
  border-radius: 12px;
  height: 80px;
  color: white;
}

.suggested-algo-know-more-btn {
  background-color: #6977e3;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.suggested-algo-know-more-btn:hover {
  background-color: #4156f9;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.suggested-algo-know-more-btn2 {
  background-color: #07939f;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.suggested-algo-know-more-btn2:hover {
  background-color: #068893;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.suggested-algo-know-more-btn3 {
  background-color: #264aa5;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.suggested-algo-know-more-btn3:hover {
  background-color: #163da0;
  padding: 12px;
  border-radius: 5px;
  color: white;
  border: none;
}

.card-invest-algo {
  margin-bottom: $grid-gutter-width;
  box-shadow: $card-box-shadow;
  border-radius: 12px;
  height: 100px;
  color: white;
}

.card-invest-div-logo {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-invest-div-logo2 {
  // width: 36px;
  // height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-overview-div-logo {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: x-large;
  background-color: #5367FC;
}
.card-overview-div-logo:hover {
  background-color: #3a4dda;
}

.card-overview-div-logo-button {
  border: none;
  background: none;
  color: $color7;
}

.card-overview-image {
  width: 100%;
  overflow: hidden;
  margin-top: 8px;
  opacity: 50%;
}
.card-my-algos-image {
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
  opacity: 50%;
}

.card-overview-div-image {
  height: 90px;
  overflow: hidden;
  border-radius: 12px;
}

.card-myAlgo-div-image {
  height: 100px;
  overflow: hidden;
}

.card-invest-logo {
  width: 25px;
  height: 25px;
}

.plan-button {
  background-color: transparent;
}
.plan-button-text {
  font-size: 20px;
  font-weight: 500;
  color: $colorText1;
}

.shepherd-button {
  &.btn-success:not(:disabled):hover {
    background: darken($success, 2.5%);
    color: $white;
  }

  &.btn-light:not(:disabled):hover {
    background: rgba(var(--#{$prefix}light-rgb), 0.75);
    color: var(--#{$prefix}dark);
  }

  &.btn-primary:not(:disabled):hover {
    background: darken($color1, 2.5%);
    color: $white;
  }
}

.pledge-table-header {
  background-color: rgba(83, 103, 252, 0.1);
  border-radius: 5px;
}
.pledge-table-input {
  border-radius: 5px;
  border: 1px solid #ece0e0;
  padding: 5px;
} 
.summary-table-header {
  background-color: $color9;
  color: rgba(73, 80, 87, 1);
  font-size: 12.8px;
  font-weight: 600;
}

.earning-table-header {
  background-color: rgba(135, 138, 153, 0.05);;
  color: $color1;
  font-size: 12.8px;
  font-weight: 600;
}

.summary-table-tr {
  color: $colorText1;
  font-size: 14px;
  font-weight: 400;
  &:nth-child(0) {
    color: $color1;
  }
}

.summary-table-tr:hover {
  color: $colorText1 !important;
  font-size: 14px!important;
  font-weight: 400!important;
  &:nth-child(0) {
    color: $color1!important;
  }
}

.refAndEarnButton {
  height: 46px;
    border-radius: 25px;
    width: 115px;
    border: none;
    background-color: #05cd99;
    color: #ffffff;
}

.refAndEarnButton:hover {
  height: 46px;
    border-radius: 25px;
    width: 115px;
    border: none;
    background-color: #05b989;
    color: #ffffff;
}

.refAndEarnButton2 {
  height: 46px;
    border-radius: 25px;
    width: 215px;
    border: none;
    background-color: rgba(83, 103, 252, 1);
    color: #ffffff;
}

.refAndEarnButton2:hover {
  height: 46px;
    border-radius: 25px;
    width: 215px;
    border: none;
    background-color: rgb(57, 79, 250);
    color: #ffffff;
}
.refAndEarnButton3 {
height: 46px;
border-radius: 25px;
border: none;
color: black;
background-color: rgba(255, 223, 53, 1);
padding: 0 20px;
}

.refAndEarnButton3:hover {
  height: 46px;
  border-radius: 25px;
  border: none;
  color: black;
  background-color: rgb(240, 203, 17);
  padding: 0 20px;
  }
  
.plan-summary-table-tr {
  color: $colorText1;
  font-size: 14px;
  font-weight: 400;
  &:nth-child(2) {
    color: $color1;
  }
  &:nth-child(3) {
    color: $color1;
  }
}

.plan-summary-table-tr2 {
  color: $colorText1;
  font-size: 14px;
  font-weight: 400;
  &:nth-child(1) {
    color: $color1;
  }
  &:nth-child(2) {
    color: $color1;
  }
}

.select-segment-type {
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 180px;
}

.summary-table-checkbox {
  color: $colorText1;
  font-size: 14px;
  font-weight: 400;
  &:nth-child(1) {
    text-align: center;
  }
}

.summary-table-tr:first-child {
  color: $color1;
}

.summary-table-date {
  border-radius: 5px;
  height: 35px;
}
.summary-table-date2 {
  border-radius: 5px;
  height: 35px;
  width: 35px;
}

.border-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.summary-table-page-number {
  border-radius: 5px;
  margin-right: 5px;
  height: 30px;
  width: 25px;
  border: 0;
  background-color: $color1;
  color: $color7;
}

.footer-text{ 
  text-align: justify;
}

.active-box-shadow {
  box-shadow: 6px 0px 20px 6px rgba(112, 144, 176, 0.2);
}

.custom-margin {
  margin-top: 12.6px;
  margin-bottom: 12.6px;
}

.custom-padding {
  padding-top: 16px;
  padding-bottom: 14px;
}

.cart_badge {
  background-color: rgba(83, 103, 252, 0.1);
  color: $color1;
  border-radius: 5px;
}

.custom-border {
  border-bottom: 1px solid $color6;
}

.custom-border3 {
  border-bottom: 2px solid rgba(220, 219, 219, 0.572) !important;
}
.custom-border4{
  border-top: 2px solid rgba(220, 219, 219, 0.572) !important;
}
.custom-border2 {
  border-bottom: 1px solid $color14;
}

.selected-box-border {
  border: 1px solid $color1;
}

.non-selected-box-border {
  border: 1px solid $color7;
}

.message-read {
  //opacity: 0.5;
  border: 1px solid $color21;
  border-radius: 12px;
}
.message-read-icon {
  opacity: 0.5;
}

.main-content-div {
  position: relative;
}
.main-content-div::before,
.main-content-div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 135px;
  background-image: url("../../../images/logo/Bgimage.png");
  background-position: top left;
  background-repeat: repeat-y;
  background-size: contain;
}
.main-content-div::before {
  left: auto;
  right: 0;
  background-position: top right;
  background-image: url("../../../images/logo/afer-image.png");
}

.main-content-div-notification {
  min-height: 100vh;
}

.suggested-algo-bg {
  height: auto;
  background-image: url("../../../images/logo/highlights_bg.png");
  background-size: contain;
}

.input-group-width-25 {
  width: 25%;
}

.input-group-width-50 {
  width: 30%;
}

.my-algos-border {
  border-top: 1px dashed $color21;
}

// profile page and components

.copy-to-clip-button {
  border: none;
  background: transparent;
  margin-top: -5px;
}

.copy-to-clip-button2 {
  border: none;
  background: transparent;
  //margin-top: 5px;
}

.profile-round-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.profile-active-tab-border {
  border-bottom: 1px solid $color14 !important;
  padding: 8px 20px !important;
}

.profile-active-tab-active {
  background-color: $color9;
  color: $color1;
  border-radius: 5px;
}

.center-padding {
  padding: 3px 6px 5px 6px;
}

.pledge-button {
  border: none;
  background-color: $color1;
  border-radius: 5px;
  color: #fff;
}

.pledge-button:hover {
  border: none;
  background-color: $color6;
  border-radius: 5px;
  color: #fff;
}
.unpledge-button {
  border: none;
  background-color: $color3;
  border-radius: 5px;
  color: #fff;
}

.unpledge-button:hover {
  border: none;
  background-color: #05ac81;
  border-radius: 5px;
  color: #fff;
}
.suggested-algo-dashboard-img {
  height: 55px;
  width: 55px;
  background-color: #fff;
  border-radius: 5px;
}

.suggested-algo-dashboard-img2 {
  height: 60px;
  width: 20%;
  background-color: #fff;
  border-radius: 5px;
}

.refer-and-earn-border-div {
  // left: auto;
  // right: 0;
  // background-position: top right;
  width:100%;
  height: auto;
  background-image: url("../../../images/logo/refAndEarnBorder.png");
}

.profile-active-tab {
  //background-color: $colorText5;
  color: $colorText5;
  //border-radius: 5px;
  cursor: pointer;
}

.profile-main-head {
  border-bottom: 1px solid $color14 !important;
  //padding: 8px 20px !important;
}

.profile-edit-button1 {
  border: none;
  background: none;
  text-decoration: underline;
}

.profile-edit-button2 {
  border: none;
  background: none;
  text-decoration: none;
}

.dative-mobile {
  background-color: $color15;
}

.learn-more {
  text-decoration: underline;
  color: $color1;
}

.nominee-form-input {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid $color11;
}

.nominee-form-input2 {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid $colorText7;
}


.nominee-form-input-select {
  width: -webkit-fill-available;
  padding: 5px;
}

.nominee-details {
  border-radius: 5px;
  border: 1px solid $color17;
}

.selected-bank-details {
  border-radius: 5px;
  border: 1px solid $color1;
}

.bank-details-image-div {
  width: 55px;
  height: 55px;
  background-color: $color18;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bank-details-image {
  width: 24px;
  height: 24px;
}
.bank-details-badge {
  background-color: $color19;
  color: $color3;
  border-radius: 5px;
}
.bank-details-badge2 {
  background-color: #fff0cc61;
  color: $color12;
  border-radius: 5px;
}
.security-qr {
  width: 173px;
}
.security-qr-text {
  margin: auto 30px;
}

.additional-switch {
  margin: auto 0;
}

.additional-status {
  font-size: 12px;
  font-weight: 400;
  color: $color1;
  text-decoration: underline;
}

.additional-transactions {
  border-bottom: 1px solid $color14;
  padding-bottom: 10px;
}
.nav-link {
  padding: 0.75rem 0.75rem !important;
}
// profile page and components End

.order-list-img {
  border-radius: 50%;
  background-color: $color23;
  display: flex;
  justify-content: center;
    align-items: center;
}

.order-list-img img { 
  max-width: 80%;
  height: fit-content;
}

// shadow

.right-left-shadow {
  // box-shadow: 14px 17px 40px 4px rgb(112 144 176 / 8%) !important;
  //box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08), -14px 17px 40px 4px rgba(112, 144, 176, 0.08) !important; //TODO 
  box-shadow: 1px 9px 8px 8px rgba(112, 144, 176, 0.08);
}

//shadow end

.checkout-btn2 {
  border: 1px solid $color1;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.checkout-btn2:hover {
  border: 1px solid $color1;
  border-radius: 5px;
  background-color: $color1;
  color: #FFF;
}

.checkout-btn4 {
  border: 1px solid $color1;
  border-radius: 5px;
  background-color: $color1;
  color: #FFF;
}

.checkout-btn41 {
  border: 1px solid $color1;
  border-radius: 5px;
  background-color: $color1;
  color: #FFF;
  opacity: .3;
}

.checkout-btn3 {
  border: 1px solid $color3;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.checkout-btn5 {
  border: 1px solid $color3;
  border-radius: 5px;
  background-color: $color3;
  color: #FFF;
}

.checkout-btn3:hover {
  border: 1px solid $color3;
  border-radius: 5px;
  background-color: $color3;
  color: #FFF;
}

.resend-otp-btn {
  border: none;
    background: none;
    color: blue;
    text-decoration: underline;
}

// model popup

.modal-content-Button {
  //width: 99px;
  height: 39px;
  background: $color1;
  border: 1px solid $color1;
  border-radius: 5px;
}

.modal-input-element {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $colorText6;
}
.modal-input-button {
  border-radius: 3px;
}
// model popup end
// buttons 
.nominee-page-button-save {
  //width: 137px;
  height: 40px;
  border-radius: 5px;
}

.nominee-page-button-save-2 {
  width: 110px;
  height: 40px;
  border-radius: 5px;
}

// .Toastify__progress-bar {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 5px;
//   z-index: var(--toastify-z-index);
//   opacity: 0.7;
//   transform-origin: right !important;
// }
// comm
// .Toastify__toast {
//   border-bottom: 5px solid #07bc0c !important;
// }
// .Toastify__progress-bar--success {
//   background: var(--toastify-color-progress-success);
//   //display: none !important;
//   opacity: 0 !important;
// }
// .Toastify__progress-bar--info {
//   background: var(--toastify-color-progress-info);
//   //display: none !important;
//   opacity: 0 !important;
// }
// comm
.add-bank-button{
  height: 30px;
  border-radius: 5px;
}

.additional-page-button-save {
  width: 122px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: $color1;
  color: #FFF;
}

.additional-page-button-save222 {
  // width: 89px;
  // height: 40px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: $color1;
  color: #FFF;
}

.tooltip-class-header {
  background-color: $color1;
  color: #FFF;
  border-radius: 5px;
  border: none;
}


.additional-page-button-save:hover {
  background-color: #4052dc;
  color: #FFF;
}

.additional-page-button-save222:hover {
  background-color: #4052dc;
  color: #FFF;
}

.additional-page-button-save223 {
  // width: 89px;
  // height: 40px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: $colorText3;
  color: #FFF;
}

.additional-page-button-save223:hover {
  background-color: #d84133;
  color: #FFF;
}

.esign-btn {
  border: none;
  background-color: $color1;
  color: $color18;
  margin-left: 20px;
  font-size: 12px;
  height: 30px;
  padding: 2px 10px;
  border-radius: 5px;
}

.esign-btn2 {
  border: none;
  background-color: $color1;
  color: $color18;
  border-radius: 5px;
  padding: 2px 10px;
}

.esign-btn3 {
  border: none;
  background-color: $color1;
  color: $color18;
  margin-right: 20px;
  font-size: 12px;
  height: 30px;
  padding: 2px 10px;
  border-radius: 5px;
}

.width-mobile-25 {
  width: 25%;
}

.width-mobile-50 {
  width: 50%;
}

.additional-page-button-cancel {
  width: 89px;
  height: 40px;
  border: none;
  background-color: $color9;
  color: $color1;
  border-radius: 5px;
}
.additional-page-button-cancel:hover {
  background-color: $color20;
}

.bank-edit-model-icon-button {
  border: none;
  background: none;
}
// buttons end

.available-feature-heading {
  width: 50%;
}

.badge-color-profit-1 {
  color: #05cd99;
  background-color: rgba(5, 205, 153, 0.18);
  // width: 60.83px;
  // height: 22px;
  border-radius: 5px;
}

.badge-color-loss-1 {
  color: #f15b4d;
  background-color: rgba(241, 113, 113, 0.1);
  // width: 60.83px;
  // height: 22px;
  border-radius: 5px;
}


.badge-color-loss-pending {
  color: #f5b010;
  background-color: #eeeed8;
  // width: 60.83px;
  // height: 22px;
  border-radius: 5px;
}

.padding-nav-item-text {
  padding: 15px 18px !important;
}

.table-bye {
  background-color: rgba(5, 205, 153, 0.18);
  padding: 3px 8px;
  border-radius: 5px;
  text-transform: uppercase;
  color: $color3;
}
.table-sell {
  background-color: rgba(241, 113, 113, 0.18);
  padding: 3px 8px;
  border-radius: 5px;
  text-transform: uppercase;
  color: $colorText3;
}
.pagination-pre {
  padding: 6px !important;
  border-radius: 5px !important;
  border: 1px solid $color14 !important;
  background-color: $color7 !important;
  margin: 0 8px !important;
}
.pagination-pre-act {
  color: $color1 !important;
}  
.pagination-pre-act:hover {
  background-color: $color1 !important;
  color: $color16 !important;
} 
.pagination-pre-dis {
  color: $colorText1 !important;
}
.pagination-but {
  padding: 3px 10px !important;
  margin-right: 8px !important;
  border-radius: 5px !important;
  border: 1px solid $color1 !important;
  
}
.pagination-but-ac {
  background-color: $color1 !important;
  color: $color7 !important;
}
.pagination-but-dis {
  background-color: $color7 !important;
  color: $color1 !important;
}
.pagination-but-dis:hover {
  background-color: $color1 !important;
  color: $color7 !important;
}
.width-mobile-50-2 {
  width: 100% !important;
  }

@media (max-width: 1200px) {
  // .dashboard-widget-bottom-margin {
  //   margin-bottom: 20px !important;
  // }
  .dashboard-widget-bottom-margin {
    margin-bottom: 20px !important;
  }
  .margin-mobile-row {
    margin-bottom: 20px !important;
  }
  .algo-card-padding {
    padding: 20px 15px !important;
  }
  .margin-tab-row-non {
    margin-bottom: 0px !important;
  }
  .width-mobile-50-2 {
    width: 100% !important;
    }
  //
  // .text-4 {
  //   font-size: 11px;
  //   font-weight: 500;
  // }
  .text-11 {
    font-size: 10px;
    font-weight: 400;
  }
}

@media (max-width: 1084px) {
  // .dashboard-widget-bottom-margin {
  //   margin-bottom: 20px !important;
  // }
  .input-group-width-50 {
    width: 25%;
  }
  .month-picker {
    width: 80% !important;
    padding: 5px 5px !important;
    text-align: center !important;
    border-radius: 5px;
    border: 1px solid #80808080;
  }
  .dashboard-widget-bottom-margin {
    margin-bottom: 20px !important;
  }
  .margin-mobile-row {
    margin-bottom: 20px !important;
  }
  .algo-card-padding {
    padding: 20px 15px !important;
  }
  .margin-tab-row-non {
    margin-bottom: 0px !important;
  }
  //
  .text-4 {
    font-size: 11px;
    font-weight: 500;
  }
}

@media (max-width: 726px) {
  .margin-mobile-row {
    margin-bottom: 20px !important;
  }
  .dashboard-widget-bottom-margin {
    margin-bottom: 20px !important;
  }
  .margin-tab-row-non {
    margin-bottom: 0px !important;
  }
  .width-mobile-50 {
    width: 100% !important;
   }
   .width-mobile-50-2 {
    width: 100% !important;
    }
   .mobile-display-block {
    display: block !important;
  }
  .download-button-mobile {
    justify-content: center;
  }

  .width-mobile-25 {
    width: 50%;
  }
  .width-mobile-50 {
  width: 100% !important;
  }
  .width-mobile-50-2 {
    width: 100% !important;
    }
  .input-group-width-25 {
    width: 50%;
  }
  .holding-download {
    // align-items: center !important;
    // justify-content: center !important;
  }
  .mobile-statement-input-center {
    justify-content: center !important;
  }
  .month-picker {
    width: 100% !important;
    //padding: 5px 160px !important;
    text-align: center !important;
    border-radius: 5px;
    border: 1px solid #80808080;
  }
  .date-picker-statements {
   // padding: 5px 150px !important;
    text-align: center !important;
  }
  .text-center-statement {
    text-align: center !important;
  }
  .statement-search {
    display: flex;
    justify-content: center;
  }
  .input-group-width-50 {
    width: 100%;
  }
  .download-button-mobile {
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%!important;
  }
}
@media (max-width: 600px) {
   .item-hide {
    display: none !important;
  }
  .margin-mobile-row2 {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  .dashboard-widget-bottom-margin {
    margin-bottom: 5px !important;
  }
  .dashboard-widget-bottom-margin2 {
    margin-bottom: 20px !important;
  }
  .mobile-padding {
    padding-bottom: 10px !important;
  }
  .margin-mobile-row {
    margin-bottom: 20px !important;
  }
  .margin-mobile-row-non {
    margin-bottom: 0px !important;
  }
  .margin-mobile-start {
    margin-left: 10px !important;
  }
  .margin-top-remove {
    margin-top: 0px !important;
  }
  .padding-buttom-remove {
    padding-bottom: 0px !important;
  }
  .padding-buttom-remove2 {
    padding-bottom: 20px !important;
  }
  .padding-buttom-remove3 {
    padding: 8px 10px !important;
  }
  .dashboard-widget-bottom-margin {
    margin-bottom: 10px !important;
  }
  .margin-start-auto {
    margin-left: 0px !important;
  }
  .display-block {
    display: block !important;
  }
  .margin-top-33 {
    margin-top: 10px;
  }
  .margin-right-33 {
    margin-right: 5px !important;
  }
  .checkout-1 {
    display: flex !important;
    justify-content: center !important;
  }
  .statement-menu {
    display: flex;
    /* justify-content: flex-start !important; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 60px;
    white-space: nowrap;
  }
  .order-table {
    overflow: auto !important;
    width: 100% !important;
    margin: 0px auto !important;
    border: 1px solid #5367fc !important;
    border-radius: 5px !important;
  }
  .share-model-middle {
    margin: 0 auto;
  }
  .mobile-display-block {
    display: block !important;
  }
  .input-group-width-50 {
    width: 100%;
  }
  .download-button-mobile {
    justify-content: center;
  }
  .cart-valid {
    display: flex;
    align-items: center;
  }
  .change-plan-btn {
    border: 1px solid $color1;
    border-radius: 5px;
    color: $color1;
    padding: 3px !important;
    font-size: 12px !important;
  }
  .dashboard-top-text {
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .dashboard-top-text2 {
    text-align: center;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .cart-image-mobile {
    height: 3rem;
    width: 3rem;
  }
  .width-mobile-25 {
    width: 50%;
  }
  .width-mobile-50 {
  width: 100% !important;
  }
  .width-mobile-50-2 {
    width: 100% !important;
    }
  .input-group-width-25 {
    width: 50%;
  }
  .order-image {
    width: 120px !important;
  }
  .text-122 {
    font-size: 20px !important;
    font-weight: 500;
  }
  .text-9-1 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .holding-download {
    // align-items: center !important;
    // justify-content: center !important;
  }
  .mobile-statement-input-center {
    justify-content: center !important;
  }
  .month-picker {
    width: 100% !important;
    //padding: 5px 160px !important;
    text-align: center !important;
    border-radius: 5px;
    border: 1px solid #80808080;
  }
  .date-picker-statements {
   // padding: 5px 150px !important;
    text-align: center !important;
  }
  .text-center-statement {
    text-align: center !important;
  }
  .statement-search {
    display: flex;
    justify-content: center;
  }
  //text
  .text-1 {
    font-size: 10px;
    font-weight: 400;
  }
  .text-4 {
    font-size: 11px;
    font-weight: 500;
  }
  .text-6 {
    font-size: 15px;
    font-weight: 500;
  }
    
  .text-21 {
    font-size: 10px;
    font-weight: 300;
  }

}



// .div-box-shadow {
//   box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
// }
