.sub-access-btn:hover {
  text-align: center;
  padding: 0px 0px 0px 0px;
  border: 0;
  border-radius: 5px;
  width: 110px;
  /* height: 39px !important; */
  background-color: #02a8b5 !important;
}
/* MediaPlayer.css */

/* Container for the media player */
.media-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Responsive styles for video */
.responsive-video {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure it doesn't exceed the container's width */
}

/* Optional: Add some padding for mobile screens */
@media (max-width: 768px) {
  .media-player {
      padding: 10px; /* Add padding for mobile devices */
  }
}
.circle-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;

}

.circle-text {
  text-align: center;
}

.notification-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.notification-content {
  flex-grow: 1;
  padding-right: 50px; /* Ensure space for the play button */
  display: flex;
  align-items: center;
}

.circle-container {
  position: absolute;
  right: 10px; /* Adjust to position the play button correctly */
  top: 50%;
  transform: translateY(-50%);
}

.circle {
 

  color: rgb(19, 5, 5); /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.circle-text {
  text-align: center;
}


