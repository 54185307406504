
.logo_wr{
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 5rem;
}

.header_wr{
  display: flex;
  justify-content: space-between;
  padding:0px 15px;
  align-items: center;
  background: #FFF;
}

.header_wr .profile_wr_right,
.header_wr .p_left-side{
    align-items: center;
}

.top_sub_menu .right-side,
.top_sub_menu .left-side{
    align-items: center;
}

.top_bar{
  box-shadow: 0px 5px 20px 0px #C9C9C940;
}
.top_sub_menu_wr{
  border-bottom: 1px solid #F7F7FF;
}

.top_sub_menu> ul{
  align-items: center;
  display: flex;
  padding: 15px 40px 0px 17px;

}

.menu_wr a{
  margin-left: 10px;
  color:  #545A6D;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px; 
}
.header_wr .popup-trigger {
  padding: 0 5px;
}

.search_bar{
  position: relative;
}

.header_search_bar input{
  
  height: 47px;
  background-color: #f6f6fc;
  color: #A3AED0;
  font-weight: 400;
  border: none;
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  max-width: 280px;
  width: 100%;
}

.search_bar input{
  padding-left: 5px;
  height: 38px;
  font-weight: 400;
  border: none;
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  max-width: 280px;
  width: 100%;
  font-size: 14px;
  background-color: #fff;
}
.top_sub_menu .header_wr .search_bar input {
  padding-left: 40px;
  background-color: #f8f9ff;
}
.search_bar img{
  position: absolute;
  top: 10px;
  right: 15px;
}
.top_sub_menu .header_wr .search_bar img {
  left: 60px;
  right: auto;
}
.search_bar input:focus{
  border: none;
  box-shadow: none;
  background-color: #F7F7FF; 
}


.bell_icon{
  width: 44px;
  height: 43px;
  background: #EFEFEF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}

.bell_icon img{
  width: 24px;
  height: 24px;
}



.not_count{
  width: 20px;
  height: 20px;
  background-color: #FF0707;
  border-radius: 50%;
  color: #fff;
  display: flex;  
  justify-content: center;
  align-items: center; 
  position: absolute;
  top: -2px;
  font-size: 12px;
  right: -1px;
}

.profile_wr{
  display: flex;
  align-items: center;
  background-color: #F5F8FF;
  padding: 10px 15px 10px 10px;
  width: auto;
  max-width: 20rem;
}
.profile_wr img{
  width: 48px;
  height: auto;
}
.profile_wr > div{
  flex-direction: column;
}
.profile_wr > div span:first-child{
  font-size: 14px;
  font-weight: 500;
}

.profile_wr_right{
  display: flex;
  justify-content: center;
  align-items: center;

}

.p_sub_name{
  color: #545A6D;
  font-size: 12px;
  font-weight: 500;
}

/* dashboard css */

.main_wr{
  background: #F8F9FF;
  background-position: center;
  object-fit: contain;
  background-size: 100%;
}

.main_body_wr{
  padding: 18px 0;
}

.main_body_heading{
  color:  #545A6D;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 25px;
}

.my_referral_wr{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
  padding: 25px;
}

.add_referral_wr{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
  padding: 25px; 
}

.my_refferal_icon_wr{
  background-color: #008490;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.my_refferal_text{
    color: #231F20;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-left: 15px;
}

.acc_process_heading_text{
  color: #8B8B8B;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.acc_process_heading_value{
  color: #5367FC;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 2.56px;
  margin-top: 16px;
}

.my_reff_first_child{
  width: 50%;
  padding: 0 36px;
}
.my_reff_sec_child{
  width: 50%;
  padding: 0 36px;
}

.my_reff_child_wr{
  padding-top: 25px;
  padding-bottom: 14px;
}

.my_earnings_wr{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
  padding: 25px;
  margin-top: 20px;

  justify-content: space-between;
}

.request_payout_wr{
  border-radius: 10px;
  border: 2px solid #5367FC;
  background: #FFF;
  padding: 10px 25px;
}

.request_payout_wr span{
  color: #5367FC;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.earnings_items_wr{
  justify-content: space-between;
}

.image_wr{
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #EBEDFF;
  justify-content: center;
  display: flex;
  padding: 10px;
}
.image_wr img {
max-width: 100%;
}

.my_earning_header{
justify-content: space-between;
}

.earnign_item_item_wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.earning_text{
  margin-top: 15px;
  margin-bottom: 5px;
}

.line{
  width: 1px;
  height: 65px;
  background: #E9E9E9;
  align-self: end;
}

.earning_text{
  color: #8B8B8B;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.inpute_field_wr{
  margin-top:12px ;
  border: none;
  padding: 10px;
  outline: none;
  padding-left: 20px;
  width: 100%;
  color: #CDCDCD;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  background-color: #FFF;
  box-shadow: 0px 3px 10px 0px rgba(137, 137, 137, 0.25);
  margin-bottom: 10px;
}

.inpute_field_wr::placeholder {
  color: #CDCDCD;
}
.form-check-label{
  color: #231F20;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.form-check{
  padding-right: 30px;
}

.form_fields_wr span:first-child{
  color: #545A6D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form_fields_wr .p-label{
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color:#231F20;
}

.add_frd_wr{
  margin-top: 30px;
    justify-content: flex-end;
}

.add_frd_wr span{
  color: #5367FC;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.term_conditon_wr{
  align-items: center;
}

.term_conditon_wr p{
  color: #231F20;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  /* align-items: center; */
  margin-left: 10px;
}

.term_conditon_wr input{
  margin-top: -14px;
  position: relative;
  top: 2px;
}
.term_conditon_wr label{
  font-size: 12px;
}

.term_condition_blue{
  color:blue
}
.referral-link-box {
  position: relative;
  margin-bottom: 20px;
}
.referral-link-box span {
  color: #231F20;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.referral-link-box .referral-link-input {
  position: relative;
}
.referral-link-box .referral-link-input .copy-icon{
  position: absolute;
  right:10px;
  top:24px;
}

.referral-link-box .referral-link-input input:focus {
  box-shadow: 0px 3px 10px 0px rgba(137, 137, 137, 0.25);
}
.referral-link-box .referral-link-input input {
 width: 100%;
 color: #797979;
 font-weight: 400;
 font-size: 14px;
 height: 45px;
}
.referral-link-box .referral-link-input div {
  position: absolute;
  right: 0;
  background: #5367fc;
  color: #fff;
  height: 45px;
  width: 80px;
  text-align: center;
  line-height: 45px;
  top: 12px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.invite_button_wr{
  justify-content: center;
  align-items: center;
}

.invite_button{
  border-radius: 10px;
  background: #5367FC;
  display: inline-block;
  padding: 12px 20px;
  width: 100%;
  max-width: 240px;
  text-align: center;
  margin-bottom: 30px;
}

.invite_button span{
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.share_wr{
  justify-content: center;
  margin-top: 20px;
}

.share_img_wr{
  width: 72%;
  justify-content: space-between;
  display: flex;
  padding-bottom: 14px;
}

.bottom_container_wr{
  padding: 20px 0 !important;
}

.reffer_frnd_bottom_wr{
  justify-content: space-between; 
  border-radius: 10px;
  opacity: 0.9;
  background: #0A3294;
  box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
  padding: 30px; 
}

.points_wr{
  padding-bottom: 25px;
}
.bottom_rff_ern_leftSide{
  padding-left: 20px;
}

.bottom_rff_ern_leftSide h5{
  color: #FFF;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.points_row_wr span {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.points_wr {
    padding-bottom: 5rem;
}
.term_condition{
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
}

.bottom_rff_ern_rightSide img{
  width: 272px;
  height: auto;
}

.background_img_wr{
  flex-direction: column;
  width: 134.966px;
height: 307px;
flex-shrink: 0;
border-radius: 12px;
background: #D9D9D9;
}


/* Explore */

.clien_wise_text{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 8px 0px;;
  /* display: flex; */
  align-items: center;
  border-radius: 10px;
  padding: 20px;
}
.clien_wise_text h4 {  
  font-weight: 600;
  color: #545A6D;
  font-size: 20px;
}

.show_ertry_wr{
  align-items: center;
  justify-content: end;
}
.lead_filter{
  margin-left: 5px;
  margin-right: 5px;
}
.show_text{
  font-size: 16px;
  font-weight: 500;
}

.search_name_no{
  max-width: 350px;
  border: 1px;
  border-style: solid;
  background-color: #fff;
  border-color: #D6D3D3;
  border-radius: 10px;
  padding-left: 10px;
}

.monthly_cat_select{
  justify-content: space-between;
  padding-right: 10px;
}

input::placeholder {
  color: #A3A3A3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search_field{
  padding:6px
}
.clnd_wr{
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.calendar_wr{
  align-items: center;

}

.calendar_wr span{
  font-size: 16px;
  font-weight: 500;
  color:#787878
}

.calendar_icon_body{
  border: 1px;
  border-style: solid;
  border-color: #D6D3D3;
  border-radius: 10px;
  width: 180px;
  padding: 0px 8px;

}

.custom-input {
  border: none;
  padding: 10px;
  font-size: 16px;
  padding-left: 7px;
  outline: none;
  flex: 6;
  width: 100%;
}

.calander_placeholder_text{
  margin-left: 10px;
}


.client_wise_income_table_wr{
   align-items: center;
   border-radius: 10px 10px 0 0;
   padding: 10px;
   
 }

 /* .client_wise_income_table_wr tr,
.client_wise_income_table_wr tr td{
  border: 0;
} */

.search_bar input:focus{
  border: none;
  box-shadow: none;
  /* background-color: #F7F7FF;  */
}

.client_wise_income_table_wr tr th{
  border-bottom: 1px;
  border-style: solid;
  border-color:  #C8C8C840;
  color:#848484;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 0px 12px 20px;
}

.client_wise_income_table_wr tr th > img{
  padding-left: 10px;
}

.client_wise_income_table_wr tr th>div{
  display: inline-block;
  
}
.client_wise_income_table_wr tr th>div>img{
  padding-left: 10px;
  
}

.client_wise_income_table_wr tr td{
  color:#000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 20px;
  justify-content: center;
}
.client_wise_income_table_wr tr:hover td{
  background: #efefef;
}

.client_wise_income_table_wr table{
  /* border-top: 1px solid #EDEDED; */
  border-bottom: 1px solid #EDEDED;
  
}

.table_footer{
  border-radius: 0 0 10px 10px;
  padding: 15px 30px;
  /* background-color: #f6f6f6; */
  justify-content: space-between;
}
.table_footer h4 {  
font-weight: 600;
color: #545A6D;
font-size: 20px;
}
.table_footer span{
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  /* margin-left: 25px 0 0 25px; */

}

.dropDown_wr{
  padding: 10px 18px;
    display: flex;
    justify-content: space-between;
}


/* search result box */

.search_result_wr{
  position: absolute;
  z-index: 2;
  padding: 6px 0;
}

.result_box{
  list-style: none;
  padding:0px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
}


.result_box li:hover{
  background-color:#5367FC;
  color: #fff; 
}
.result_box li:hover .content_text {
  background-color:#5367FC;
  color: #fff; 
}
.result_box li:hover .content_text_value {
  background-color:#5367FC;
  color: #fff; 
}

.result_box li{
  padding: 10px 35px;
  border-bottom: 1px solid;
  border-color: #BABABAA6;
}

.content_text{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #808080;
}

.content_text_value{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #231F20;
  margin-right: 10px;
  margin-left: 5px;
}


.search_bar input:focus {
  outline:none; 
  background-color: #fff;
}

/* notification */
.noti_header{
  width: 256px;
  background-color: #5367FC;
  border-radius: 6px 6px 0px 0px;
  padding: 10px;

}
.noti_body{
  background-color: #fff;
  border-radius: 0px 0px 6px 6px;
  padding:12px 10px 0px 16px;
  display: flex;
  align-items: center;
}
.noti_header h6{
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}
.msg_text{
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 5px;
}
.noti_time{
  flex-direction: row;
  display: flex;
  align-items: center;
}
.noti_time span{
  margin-left: 16px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
color: #A3AED0;
}
.msg_wr{
  display: flex;
  flex-direction: column;
  margin-left: 13px;
}

.number_wr span{
  color: #ffffff;
  font-weight: 600;
  font-size: 10px;

}
.noti_icon_wr{
  width: 36px;
  height: 36px;
  padding: 9px 8px 7px 8px;
  border-radius: 24px;
  background-color: #5367FC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number_wr{
  width: 60px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 4px;
  
  justify-content: center;
  display: flex;
  align-items: center;
}
.noti_text_wr{
  
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.see_all_noti_wr{
  width: 70px;
  height: 36px;
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  bottom: -12px;
}

.see_all_noti_wr span{
  font-weight: 500;
  font-size: 12px;
  color: #5367FC;
}

.notification_popup_wr{
  padding: 0px 0px 10px 0px;
}

.noti_footer{
  padding: 10px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.noti_footer span{
  padding: 4px 50px 6px 50px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: rgba(5, 205, 153, 0.1);
  border-color: rgba(5, 205, 153, 0.1);
  font-weight: 500;
  font-size: 14px;
  color: #05CD99;
}
.profile_popup{    
  padding: 10px 0px 10px 0px;
  width: 16em;
}

.profile_item_wr{
  background-color: #5367FC;
  padding: 10px;
}sdfsdfs
.profile_item_wr span{
  font-size: 14px;
  font-weight: 400;
  color: #FFF;
}
.logout_text{
  color: #000 !important;
}

.deactive_color{
  background-color: #FFF;
}

.deactive_img{
  background-color: #FFF !important;
}
.profile_item_wr img{
  background-color: #5367FC;
  margin-right: 5px;
  margin-left: 20px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 12px!important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 10px!important;
  border-radius: 10px!important;
}
.top_sub_menu .notification_wr,
.top_sub_menu .profile_wr, 
.top_sub_menu .profile_popup {
  cursor: pointer;
}
ul.pagination {
  padding: 0 0 0 0!important;
  margin-left: -10px;
  margin-top: 15px;
  margin-bottom: 0;
}
.table_footer {
  padding: 15px 15px;
}  

.sidebar-close {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: 0;
  margin-top: -10px;
  margin-bottom: -15px;
}
.sidebar-close::before {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #333;
  border-radius: 2px;
  top: 20px;
  left: 5px;
  position: absolute;    
  transform: rotate(45deg);
}
.sidebar-close::after {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #333;
  border-radius: 2px;
  top: 20px;
  left: 5px;
  position: absolute;
  transform: rotate(-45deg);
}
.mobile-toggle-icons {
  display: none;
  /* margin-left: 0;
  margin-right: auto; */
  width: 28px;
  height: 32px;
  cursor: pointer;
  padding: 0px;
  margin-left: 10px;
  margin-right: 0px;
  position: relative;
}
.mobile-toggle-icons > span {
  height: 2px;
  background-color: #333;
  border-radius: 2px;
  display: block;
  width: 24px;
  cursor: pointer;
  position: absolute;
  left: 0;
  transition: 0.4s;
}
.mobile-toggle-icons > span:first-child {
  top: 10px;
}
.mobile-toggle-icons > span:nth-child(2) {
  top: 17px;
}
.mobile-toggle-icons > span:last-child {
  top: 25px;
}
.menu-open .mobile-toggle-icons {

}
.menu-open .mobile-toggle-icons > span:first-child {
  top: 15px;
  transform: rotate(45deg);
}
.menu-open .mobile-toggle-icons > span:nth-child(2) {
  opacity: 0;
}
.menu-open .mobile-toggle-icons > span:last-child {
  top: 15px;
  transform: rotate(-45deg);
}
.my-plan-row .col-sm-6.col-lg-4 {
   margin-bottom: 20px;
} 
.refer-earn-tab {
  display: flex;
  margin: 0;
  padding: 0;
}
.refer-earn-tab li {

}
.refer-earn-tab li span {
  padding: 15px 20px 10px 20px;
  display: block;
  border-bottom: 3px solid;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}
.ref-and-earn-button {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
.form_fields_wr input.inpute_field_wr:focus {
  box-shadow: 0px 3px 10px 0px rgba(137, 137, 137, 0.25);
}
.client_wise_income_table_wr .pagination button{
background: #fff;
border: 1px solid #ccc;
padding: 0px 10px;

}
.client_wise_income_table_wr .pagination p{
  padding: 3px 10px;
  border: 1px solid #ccc;
  margin: 0 5px !important;
}

@media(max-width:767px) {
  .reffer_frnd_bottom_wr{  
    padding: 20px; 
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .reffer_frnd_bottom_wr .points_wr {
    padding-bottom: 1rem;    
  }
  .reffer_frnd_bottom_wr .bottom_rff_ern_leftSide {
    padding-left: 0;
  }
  .reffer_frnd_bottom_wr .bottom_rff_ern_rightSide {
    padding-top: 30px;
    text-align: center;
    margin: auto;
  }
  .bottom_rff_ern_rightSide img {
      width: 150px;
      height: auto;
  }
  .my_referral_wr .my_reff_first_child,
  .my_referral_wr .my_reff_sec_child {   
    padding: 0 0px;
    text-align: center;
   }
   .my_earnings_wr {
    margin-bottom: 20px;
  }
  .earnign_item_item_wr {
    align-items: center;
    padding-left: 0;
    flex: 0 0 49%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  .earnings_items_wr.pt-5 {
    /* flex-direction: column; */
    padding-top: 1rem !important;
    flex-wrap: wrap;
  }
  .earnings_items_wr .line {
    width: 1px;
    height: 25px;
    background: #ffffff;
  }
  .term_conditon_wr.pt-5 {
    padding-top: 1rem !important;
  }
  .search_field .search_bar.search_name_no {
    margin-bottom: 25px;
    margin-left: -10px;
  }
  .clien_wise_text .monthly_cat_select {
    flex-direction: column;
  }
  .clien_wise_text .monthly_cat_select  .show_ertry_wr {   
    padding: 0px;
    justify-content: flex-start;
  }
  .client_wise_income_table_wr tr td {
    padding: 12px 15px;
  } 
  .client_wise_income_table_wr tr th:first-child,
  .client_wise_income_table_wr tr td:first-child {
    padding: 7px 7px 7px 7px;
  }   
  .search_field .date_picker {
    margin-right: 10px;
  }
  .table_footer .pagination .deactive_circle, 
  .table_footer .pagination .active_number {
     width: 30px;
     height: 30px;
     min-width: 30px;
     min-height: 30px;
  }
  .table_footer {
    border-radius: 0;
    padding: 5px 0px;
  }  
  .table_footer .pagination  {
    padding: 0;
    margin: 15px 0 0 -10px;
  }
  .client_wise_income_table_wr {
    padding: 0;
  }
  .clien_wise_text h4 {   
    padding-left: 0;
    margin-bottom: 7px;
    font-size: 16px;
  }
  .mobile-toggle-icons {
    display: block;
  }
  .header_wr .profile_wr_right .popup-container .profile_wr {
    display: none;
  } 
  .header_wr .profile_wr_right .bell_icon {
    margin-right: 0;
  }
  .header_wr .search_bar input {
    width: 0;
    border-radius: 30px;
    padding: 0;
  }
  .search_bar.header_search_bar.ps-5 {
    padding-left: 1.5rem !important;
  }
  .top_sub_menu .header_wr .search_bar img {
    left: 34px;
  }
  .header_wr {  
    padding: 12px 0px;
  }  
  .top_sub_menu_wr {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 9;
  }
  .top_sub_menu_mobile {   
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;

    background: #fff;
    position: fixed;
    left: 0;
    top: 67px;
    width: min(80%, 300px);
    box-shadow: 0px 20px 15px 0px rgba(171, 171, 171, 0.25);
    height: calc(100vh - 67px);
    z-index: 9;
  }
  .top_sub_menu_mobile.d-flex {
    /* display: none!important; */
    left: -100%;
    transition: 0.4s;
  }  
  .menu-open .top_sub_menu_mobile {
    /* display: flex!important;  */
    left: 0;
    transition: 0.4s;
  }
  .top_sub_menu_mobile > ul {
     display: block;
     padding: 5px 20px 5px 17px;
  }  
  .top_sub_menu_mobile  .popup-container {
     display: block;
  }
  .top_sub_menu_mobile  .popup-container .popup-trigger {
     padding: 0;
  }
  .top_sub_menu_mobile .profile_item_wr img {  
    margin-left: 10px;
  }
  .top_sub_menu_mobile .profile_item_wr {
    padding: 10px;
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
  }
  .top_sub_menu_mobile .profile_wr {
    margin-bottom: 15px;
  }
  .main_body_wr {
     padding: 0px 0 0 0px;
  }
  .main_wr {   
     margin-top: 25px;
  }
  .mobile-none{
    display: none;
  }
}

@media(max-width:575px) {
  
.my_earning_header {
   flex-direction: column;
   align-items: flex-start !important;
}
.request_payout_wr {  
  padding: 10px; 
  max-width: 197px;
  text-align: center;
  margin-top: 20px;
}
.search_field .col-md-7.clnd_wr {
  padding: 0;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  gap: 15px;
}
.centered-paragraph {
  text-align: center;
}
/* new useable */

.my_referral_wr{
  padding: 15px;
}
.acc_process_heading_text{
  font-size: 11px;
  margin-bottom: 10px;
}
.refer-earn-tab li {
  width: 50%;
  text-align: center;
}
.client_wise_income_table_wr tr th{
  font-size: 14px;
  line-height: 16px;
  vertical-align: top;
  padding: 7px 7px;
}
.my_reff_child_wr{
  padding-top: 10px;
}
.acc_process_heading_value{
  font-size: 20px;
  margin-top: 0;
  font-weight: 600;
}
.add_referral_wr{
  padding: 10px;
}
.term_conditon_wr label{
  font-size: 10px;
}
.client_wise_income_table_wr tr td {
  padding: 7px 7px;
  font-size: 14px;
  font-size: 12px;
  line-height: 14px;
}
.table-pagination .pagination-buttons{
  width: 100%;
  white-space: nowrap;
}

.ml-00{
  margin-left: 51px;
}
.refer-earn-tab li span{
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}
.my_refferal_icon_wr{
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
}
.my_refferal_icon_wr img{
  height: 70%;
}
.my_refferal_text{
  font-size: 20px;
}
.my_earnings_wr{
  padding: 10px;
}
[data-layout="horizontal"] .page-content{
  padding-left: 0;
  padding-right: 0;
}
.image_wr{
  width: 35px;
  height: 35px;
  padding: 7px;
}
.earning_text{
  font-size: 12px;
}
.points_row_wr span{
  font-size: 14px;
  line-height: 22px;
}
.bottom_rff_ern_leftSide h5{
  font-size: 20px;
  margin-bottom: 10px;
}

}
.refer-earn-container{
  max-width:1320px;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .earnings_items_wr{
    flex-wrap: wrap;
  }
  .earnign_item_item_wr{
    flex: 0 0 49%;
  }
  .my_refferal_text{
    font-size: 20px;
  }
  .my_refferal_icon_wr{
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
  .my_refferal_icon_wr img{
    height:60%;
  }
  .line{
    display: none;
  }
  .inpute_field_wr{
    padding-left: 10px;
  }
  .invite_button{
    margin-bottom: 20px;
  }
  .container, .container-sm, .container-md{
    min-width: 930px;
  }
  
}

.income_client_switch_wr{
  overflow: hidden;
  border-radius: 50px;
  background-color: #F0F0F0;
  padding: 1px !important;
  height: fit-content;
}

.income_button_first{
  padding: 10px 16px;
  background-color: #5367FC;
  border: none;
  border-radius: 50px;

}
.ative_button{
  background-color: #5367FC;
  width: 50%;  
}
.deative_button{
  background-color: #F0F0F0;
  width: 50%;  
}
.ative_button_text span{
    color: #F0F0F0;
}
.deactive_btn_text span{
  color: #231F20;
}

.client_wr{
  color: #231F20;
    font-size: 16px;
    font-weight: 600;
}
.income_wr_first{
  color:#FFFFFF;
  font-size: 16px;
  font-weight: 600;
}
.income_client_switch_wr{
  overflow: hidden;
  border-radius: 50px;
  background-color: #F0F0F0;
  padding: 1px !important;
  height: fit-content;
  align-items: center;
  display: flex;
}