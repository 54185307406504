body {
    font-family: 'Poppins', sans-serif;
    color: #444444;
    margin: 0;
    padding: 0;
    background-color: #FCFCFF;
}
p,h1,h2,h3,h4,h5,h6{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color: #9E9E9E;
}
button:focus, input:focus, .form-control:focus{
    outline: none;
    box-shadow: none;
}
ul,ol{
    list-style: none;
}
.wrapper{
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    background:url('../../images/algo/back-img.png') no-repeat;
    background-size: 100% auto; 
}
.wrapper .heading-part{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0px 0 30px;
}
.wrapper .heading-part h4{
    color: #495057;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height:120% ;
    text-transform: capitalize;
}
.wrapper .heading-part .right{
    display: flex;
    align-items: center;
}
.wrapper .heading-part .right .generate-btn{
    border-radius: 4px;
    border: 1px solid #5367FC;
    padding: 10px 39px 10px 39px;
    background: transparent;
    color: #5367FC;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-right: 23px;
}
.wrapper .heading-part .right ul{
    margin: 0;
    padding: 0;
    color: #545A6D;
}
.wrapper .heading-part .right ul li{
    display: inline-block;
    color: #545A6D;
    font-size: 15px;
}

.wrapper .heading-part .right ul li a{
    color: #545A6D;
}
.wrapper .heading-part .right ul li:last-child a{
    color: #A3AED0;
}
.wrapper .filter-part{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
}
.wrapper .filter-part label{
    white-space: nowrap;
    color: #787878;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-right: 14px;
}
.wrapper .filter-part label.main{
    margin-bottom: 10px;
}
.wrapper .filter-part .input-group{
    width: 200px;
    border: 1px solid #D6D3D3;
    border-radius: 10px;
    margin-right: 20px;
}
.wrapper .filter-part .input-group .input-group-text{
    height: 100%;
    background: transparent;
    border: none;
}
.wrapper .filter-part .input-group .form-control{
    border: none;
    border-radius:10px;
    padding: 8px 10px 8px 10px;
}
.wrapper .filter-part .input-group .form-control:placeholder{
    color: #ABABAB;
}
/* .wrapper .filter-part .search-btn{
    border-radius: 4px;
    background: #5367FC;
    padding: 8px 20px 8px 20px;
    color: #fff;
    border: none;
    margin-right: 20px;
}
 .search-btn:hover{
  background-color: #4252ca;
  color: #FFF;
} */
.wrapper .filter-part .gen-btn{
    border-radius: 4px;
    background: #5367FC;
    padding: 8px 34px 8px 34px;
    color: #fff;
    border: none;
    margin-right: 20px;
}
.wrapper .dashboard-card{
    width: 100%;
    height: auto;
    position: relative;
    padding: 17px;
    background-color: #fff;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    border-radius: 12px;
    margin-bottom: 23px;
}
.wrapper .dashboard-card h5{
    color:#545A6D;
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
}
.wrapper .dashboard-card .price{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.wrapper .dashboard-card h4{
    color:#545A6D;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.2px;
    width: 70%; 
    white-space: nowrap;
}
.wrapper .dashboard-card .price .icon{
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
}
.wrapper .dashboard-card h4 .up{
    border-radius: 4px;
    background: rgba(5, 205, 153, 0.18);
    color: #05CD99;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    padding: 1.125px 6.828px 0.875px 3.891px;
    display: inline-block;
    position: relative;
    top: -4px;
    margin-left: 13px;
}
.wrapper .dashboard-card h4 .down{
    border-radius: 4px;
    background: #FCE5E5;
    color: #F17272;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    padding: 1.125px 6.828px 0.875px 3.891px;
    display: inline-block;
    position: relative;
    top: -4px;
    margin-left: 13px;
}
.wrapper .gain-card{
    border-radius: 12px;
    background:#FFF;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    padding: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapper .gain-card .left{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.wrapper .gain-card .left p{
    color: #545A6D;
    font-size: 12px;
    font-weight: 600;
    line-height: 19.5px;
    text-transform: uppercase;
    margin-right: 30px;
    min-width: fit-content;
}
.wrapper .gain-card .left h4{
    color: #545A6D;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
}
.wrapper .gain-card .up{
    border-radius: 4px;
    background: rgba(5, 205, 153, 0.18);
    color: #05CD99;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    padding: 1.125px 6.828px 0.875px 3.891px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
}
.wrapper .download-sec{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px;
}

.wrapper .download-sec .left{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
}
.wrapper .download-sec .right{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
}
.wrapper .download-sec .right label{
    white-space: nowrap; 
    margin-right: 20px;
}
.wrapper .download-sec .right label.main{
    margin-bottom: 10px;
}
.wrapper .download-sec .left label{
    color: #787878;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    margin-right: 20px;
}
.wrapper .download-sec .left label.main{
    margin-bottom: 10px;
}
.wrapper .download-sec .left .form-control.dropdown{
    width: 100%;
    box-shadow: 0px 2px 10px 0px rgba(201, 201, 201, 0.25);
    border-radius: 10px;
    border: 1px solid #D6D3D3;
    background-color: #fff;
    background:#fff url('../../images/algo/down-arrow.png') no-repeat;
    background-position: 98% center;
    height: 44px;
    margin-right: 30px;
}
.wrapper .download-sec .input-group{
    width: 200px;
    border: 1px solid #D6D3D3;
    border-radius: 10px;
    margin-right: 20px;
    flex:0 0 200px;
}
.wrapper .download-sec .input-group .input-group-text{
    height: 100%;
    background: transparent;
    border: none;
}
.wrapper .download-sec .input-group .form-control{
    border: none;
    border-radius:10px;
    padding: 8px 10px 8px 10px;
}
.wrapper .download-sec .input-group .form-control:placeholder{
    color: #ABABAB;
}
.wrapper .download-sec .download-btn{
    border-radius: 4px;
    background: #05CD99;
    padding: 9px 10px 9px 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #fff;
    border: none;
    white-space: nowrap;
    margin-left: 10px;
}
.wrapper .download-sec .download-btn:hover{
    border-radius: 4px;
    background: #04ae82;
    padding: 9px 10px 9px 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #fff;
    border: none;
    white-space: nowrap;
    margin-left: 10px;
}
.wrapper .gain-card .left p:nth-child(1){
    font-size:14px
}
.wrapper .gain-card .left p:nth-child(2){
    font-size:21px
}
/* .wrapper .download-sec .download-btn.search-btn{
    background: #4252ca;
} */
.wrapper .download-sec .download-btn.search-btn {
    border-radius: 4px;
    background: #5367FC;
    padding: 8px 20px 8px 20px;
    color: #fff;
    border: none;
    margin-right: 20px;
}
.wrapper .download-sec .download-btn.search-btn:hover{
    background: #4252ca;
}

.wrapper .table-part{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 50px;
}
.wrapper .table-part .table-tabs{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.wrapper .table-part .table-tabs .nav-tabs{
    border: none;
}
.wrapper .table-part .table-tabs .nav-link{
    border: none;
    background: transparent;
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120% ;
    text-transform: capitalize;
    border-bottom: 3px solid transparent;
    padding: 20px 45px;
}
.wrapper .table-part .table-tabs .nav-link.active{
    border-color: #5367FC;
    color: #5367FC;
}
.wrapper .table-part .table-tabs .right{
    display: flex;
    align-items: center;
}
.wrapper .table-part .table-tabs .filter{
    border-radius: 5px;
    background: #E5EDFF;
    border: none;
    margin-right: 27px;
}
.wrapper .table-part .table-tabs .right .form-group{
    display: flex;
    align-items: center;
}
.wrapper .table-part .table-tabs .right .form-group label{
    color: #231F20;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
}
.wrapper .table-part .table-tabs .right .form-group .form-control{
    width: 100px;
    box-shadow: 0px 2px 10px 0px rgba(201, 201, 201, 0.25);
    border-radius: 10px;
    border: 1px solid #D6D3D3;
    background-color: #fff;
    background:#fff url('../../images/algo/down-arrow.png') no-repeat;
    background-position: 98% center;
    height: 44px;
    margin-right: 11px;
    margin-left: 11px;
    font-weight: 500px;
}
.wrapper .table-part .table-responsive table{
    width: 100%;
    height: auto;
    position: relative; 
}
.wrapper .table-part .table-responsive table th{
    background: rgba(83,103,252,.1);
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
    color: #545A6D;
}
.wrapper .table-part .table-responsive table td{
    background: #fff;
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
    color: #545A6D;
    border-bottom: 1px solid #EBECF0;
}
.wrapper .table-part .table-responsive table td:first-child span{
    color: #5367fc;
}
.wrapper .table-part .table-responsive table td .no-res{
    font-size: 18px;
    font-weight: 400;
    color: #333 !important;
}
.wrapper .table-part .table-responsive table td p{
    display: none;
}
.wrapper .table-part .table-responsive table td .buy{
    border: none;
    border-radius: 4px;
    background: rgba(5, 205, 153, 0.18);
    color: #05CD99;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 600;
    padding:3px 15px;
}
.wrapper .table-part .table-responsive table td .sell{
    border: none;
    border-radius: 4px;
    background: #FCE5E5;
    color: #F17272;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 600;
    padding:3px 15px;
}
.algo-key{
    background-color: rgba(83,103,252,0.2);
}
.algo-key .modal-header{
    border: none;
    padding: 24px 24px 16px;
}
.algo-key .modal-header .modal-title{
    color: #5367FC;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
}
.algo-key .modal-header .close{
    cursor: pointer;
    background: none;
    border:none;
}
.algo-key .modal-body{
    padding: 24px 24px 40px;
}
.algo-key .modal-body h3{
    color:#545A6D;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
}
.algo-key .modal-body .form-check{
    margin-bottom: 16px;
}
.algo-key .modal-body .form-check .form-check-label{
    font-size: 14px;
    color: #545A6D;
    line-height: 20px;
}
.algo-key .modal-body .form-check .form-check-input:checked{
    background-color: #fff;
    border-color: #5367FC;
    box-shadow: none;
    background-image: url(../../images/algo/tick.png) !important;

}
.algo-key .modal-body .send-btn{
    border: 1px solid  #5367FC;
    background: #5367FC;
    border-radius: 4px;
    padding: 9px 37px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    margin: auto;
    display: table;
}
.algo-key .modal-body .form-group{
    width: 100%;
    display: flex;
    margin-bottom: 32px;
}
.algo-key .modal-body .form-group .form-control{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #919DA9;
    height: 40px;
}
.algo-key .modal-body p{
    color: #919DA9;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.algo-key .modal-body p .resend{
    color: #5367FC;
    font-weight: 500;
}

.wrapper .key-card{
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    padding: 16px;
}
.wrapper .key-card .top-part{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.wrapper .key-card .top-part p{
    color: #5367FC;
    font-size: 12px;
}
.wrapper .key-card .top-part .inactive{
    border-radius: 4px;
    background: rgba(241, 91, 77, 0.18);
    display: table;
    padding: 4px 8px 4px 8px;
    color: #F15B4D;
    font-size: 10.5px;
    font-weight: 600;
    line-height: 10.5px;
}
.wrapper .key-card .top-part .active{
    border-radius: 4px;
    background: rgba(8, 190, 143, 0.18);
    display: table;
    padding: 4px 8px 4px 8px;
    color: #08BE8F;
    font-size: 10.5px;
    font-weight: 600;
    line-height: 10.5px;
}
.wrapper .key-card .order-api{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.wrapper .key-card .order-api .icon{
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    margin-right: 15px;
}
.wrapper .key-card .order-api h4{
    color: #545A6D;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.wrapper .key-card .order-api p{
    color: #545A6D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wrapper .key-card table{
    width:100%;
    height: auto;
    border-bottom: 1px solid rgba(163, 174, 208, 0.50);
    margin-bottom: 25px;
}
.wrapper .key-card table th{
    color: rgba(84, 90, 109, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 5px;
}
.wrapper .key-card table td{
    color: rgba(84, 90, 109, 1);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 5px;
}
.wrapper .key-card .api-key-heading{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.wrapper .key-card .api-key-heading p{
    color: #545A6D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wrapper .key-card .api-key-heading button{
    background: none;
    border: none;
    padding: 0px;
}

.wrapper .key-card .form-group{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    margin-bottom: 16px;
}
.wrapper .key-card .form-group .form-control{
    border: 1px solid rgba(163, 174, 208, 0.50);
    border-radius: 4px;
    font-size: 12px;
    height: 32px;
    color: #545A6D;
    margin-right: 22px;
}
.wrapper .key-card .form-group button{
    border: none;
    background: rgba(83, 103, 252, 0.10);
    border-radius: 4px;
}
.search-date{
    display: none;
}
#search-close{
    display: none;
}
#search-close-b{
    display: none;
}
.scroll-row{
    overflow-y: auto;
    padding-bottom: 10px;
}
.scroll-row::-webkit-scrollbar{
    display: none;
}
.scroll-row .row{
    flex-wrap: nowrap;
}
.scroll-row .row .col-md-3{
    width: 22%;
}
.my-algo-container{
    max-width: 1300px;
}
.table-pagination{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.table-pagination button{
    background: #fff;
    border: 1px solid #5367fc;
    border-radius: 4px;
    margin-left: 2px;
    margin: 0 5px;
    min-width: 28px;
}
.table-pagination button.active-page{
    background: #5367FC;
    color:#fff;
}
.table-pagination button.active{
    color: #5367FC;
}
.table-pagination button.inactive{
    color: #5367FC;
    opacity: 0.5;
}
.table-pagination .pagination-buttons span{
    padding:2px 10px;
    border: 1px solid #ccc;
 }
@media screen and (max-width: 768px) {
    .wrapper .heading-part{
        display: block;
    }
    .wrapper .heading-part h4{
        font-size: 18px;
        margin-bottom: 16px;
    }
    .wrapper .heading-part .right{
        display: block;
    }
    .wrapper .heading-part .right ul{
        display: none;
    }
    .wrapper .heading-part .right .generate-btn{
        width: 100%;
        margin: 0;
        padding: 8px;
    }
    .wrapper .filter-part{
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 26px;
        display: none;
    }
    .wrapper .filter-part label{
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 8px;
    }
    .wrapper .filter-part .input-group{
        width: 30%;
        margin-right: 10px;
        flex: 0 0 30%;
    }
    .wrapper .filter-part .search-btn{
        margin-right: 0;
        padding: 8px 25px;
        font-size: 12px;
    }
    .wrapper .filter-part .input-group .form-control{
        font-size: 12px;
    }
    .wrapper .dashboard-card{
        padding: 10px;
    }
    .wrapper .dashboard-card .price{
        display: block;
        text-align: center;
    }
    .card-loader-img{
       margin: auto;
       display: table;
      }
    .wrapper .dashboard-card .price .icon{
        margin: auto;
        margin-bottom: 8px;
    }
    .wrapper .dashboard-card h5{
        font-size: 12px;
    }
    .wrapper .dashboard-card h4{
        font-size: 18px;
        white-space: unset;
        margin: 0;
        width: 100%;
        text-align: center;
    }
    .wrapper .dashboard-card h4 .down{
        margin: 0;
    }
    .wrapper .dashboard-card h4 .up{
        margin: 0;
    }
    .wrapper .gain{
        width: 100%;
        height: auto;
        overflow-y: auto;
    }
    .wrapper .gain .row{
        flex-wrap: nowrap;
    }
    .wrapper .gain-card .left h4{
        font-size: 18px;
    }
    .wrapper .download-sec{
        display: block;
    }
    .wrapper .download-sec .left{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .wrapper .download-sec .right{
        display: block;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .wrapper .download-sec .right label{
        margin-bottom: 10px;
        flex: 0 0 100%;   
    }
    .wrapper .download-sec .left label{
        margin-bottom: 10px;
        flex: 0 0 100%;
    }
    .wrapper .download-sec .left .form-control.dropdown{
        margin: 0;
        margin-bottom: 10px;
    }
    .wrapper .download-sec .input-group{
        flex: 0 0 48%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .wrapper .download-sec .input-group .form-control{
        font-size: 12px;
    }
    .wrapper .download-sec .download-btn{
        font-size: 12px;
        padding: 8px 15px;
        width: 100%;
        margin-bottom: 5px;
    }
    .wrapper .download-sec .input-group{
        width: 100%;
    }
    .wrapper .table-part .table-tabs{
        display: flex;
        justify-content: space-between;
    }
    .wrapper .table-part .table-tabs .filter{
        margin:0;
    }
    .wrapper .table-part .table-tabs .right .form-group{
        display: none;
    }
    .wrapper .table-part .table-tabs .nav-link{
        font-size: 14px;
        padding: 10px 15px;
    }
    /* .wrapper .table-part .table-responsive table thead{
        display: none;
    }
    .wrapper .table-part .table-responsive table tbody tr{
        display: flex;
        flex-wrap: wrap;
        border: 1px solid rgba(83, 103, 252, 0.25);
        border-radius: 10px;
        margin-bottom: 5px !important;
        background-color: #fff;
    }
    .wrapper .table-part .table-responsive table tbody tr td{
        background-color: transparent;
        border: none;
        flex: 0 0 100%;
        padding: 0;
        font-size: 12px;
    }
   
    .wrapper .table-part .table-responsive table td p{
        display: block;
        color: #000;
        font-weight: bold;
        width: 50%;
        float: left;
        padding: 10px 12px;
        background-color: #EBEDFF;
        margin-right: 12px;
    }
    .wrapper .table-part .table-responsive table td span{
        padding: 7px 12px;
        display: inline-block;
    } */
    .wrapper .key-card table tbody{
        display: flex;
        flex-wrap: wrap;
    }
     .wrapper .key-card table tbody tr{
        width: 50%;
        flex: 50%;
     }
     .wrapper .key-card table tbody tr th{
        display: block;
        width: 100%;
     }
     .wrapper .key-card table tbody tr td{
        display: block;
        width: 100%;
     }
     .wrapper .key-card{
        margin-bottom: 30px;
     }
     .search-date{
        display: block;
        color: #5367FC;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        margin-bottom: 12px;
     }
     #search-close{
        display: inline-block;
    }
    #search-close-b{
        display: inline-block;
    }

    /* //row wise */
    .gains-container {
        display: flex;
        flex-wrap: wrap;
      }
      
      .gain {
        flex: 0 0 33.33%; /* Adjust the percentage based on your layout */
        margin: 10px;
      }
      
      .gain-card {
        border: 1px solid #ccc;
        padding: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      
      .top {
        flex-grow: 1;
      }
      
      .bottom {
        display: flex;
        justify-content: space-between;
      }
      .inpute_field_wr{
        margin-top:12px ;
        border: none;
        padding: 10px;
        outline: none;
        padding-left: 20px;
        width: 100%;
        color: #CDCDCD;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        border-radius: 10px;
        background-color: #FFF;
        box-shadow: 0px 3px 10px 0px rgba(137, 137, 137, 0.25);
        margin-bottom: 10px;
      }
      .scroll-row .row .col-md-3{
        width: 85%;
      }
}
.centered-paragraph {
    text-align: center;
    /* Add any additional styles you need for the paragraph */
  }
  .centered-table {
    width: 100%;
    text-align: center;
  }
  
  .centered-cell {
    text-align: center;
  }
  body .gain-card .left .green {
    /* background-color: green; */
    color:#0AB39C;
  }
  
 body .gain-card .left .red {
    /* background-color: red; */
    color: #f15b4d;
  }
  .black {
    /* background-color: red; */
    color: #000;
  }

  .green-text {
    color: #0AB39C;
    font-size: 21px; /* Adjust the size as needed */

  }

  .black-text {
    color: #000;
    font-size: 21px;
  }  
  .red-text {
    color:#f15b4d;
    font-size: 21px; /* Adjust the size as needed */

  }

  .large-font {
    font-size: 16px; /* Adjust the size as needed */

  }
  .active-page {
    background-color: #007bff; /* Set your desired background color for active page */
    color: #007bff; 
    border-color: #007bff;
  }
  
  .inactive-page {
    background-color: #fff; /* Set your desired background color for inactive page */
    color: #000; /* Set the text color for inactive page */
  }

  .table-hover tbody tr:hover {
    background-color: #f5f5f5; /* Change to your desired hover background color */
  }
  
  /* Optional: Customize the text color on hover */
  .table-hover tbody tr:hover td {
    color: #333; /* Change to your desired text color */
  }
  .card-loader-img{
    width: 32px;
    height: 32px;
  }

  /** New CSS **/
h5.text-center-statement,
.text-end.mob-txt-amt {
   margin-bottom:6px;
}

@media (max-width:1024px) {
    .page-content {
        margin-top:20px;
    }
    .additional-transactions .form-check-label {
        margin-left: 0;
    }
}