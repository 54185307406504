.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -25px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 25px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: transparent;
  margin-bottom: 20px;
}

.add-Collateral-btn:hover {
  background-color: #3a4dda !important;
}

.Access-btn:hover {
  text-align: center;
  padding: 9px 0px 11px 0px;
  border: 0;
  border-radius: 5px;
  width: 110px;
  /* height: 39px !important; */
  background-color: #02a8b5 !important;
}
